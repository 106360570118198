<template>
	<section id="globales">
		<Header />

		<div class="importcsv">
			<input type="file" ref="globalecsv" id="globalecsv" @change="handleExcel" />
			<div class="upload_informations" v-if="is_replacing_data">
				<i class="fa-regular fa-spin fa-spinner" v-if="is_replacing_data"></i>
				<div>{{ replacing_text }}</div>
			</div>
		</div>

		<div class="simple_inferface">
			Nous avons trouvé <br /><strong>{{ globales.length }} codes</strong> et <strong>{{ zones_filtrees.length }} régions</strong> <br />lors de votre dernier import
			Excel
		</div>

		<div class="gestion_ftp">
			<div class="gauche">
				<h4>Gestion des images</h4>
				<div class="form">
					<input type="file" @change="onFileChange" accept="image/png, image/jpeg">
					<input type="text" v-model="filename" placeholder="Renommer votre fichier">
					<button @click="uploadImage">
						<span v-if="!uploadState">Envoyer sur le FTP</span>
						<span v-if="uploadState">Envoi en cours <i class="far fa-spin fa-spinner"></i></span>
					</button>
					<div class="response" v-show="uploadMessage.length > 1">{{ uploadMessage }}</div>
				</div>
			</div>
			<div class="droite">
				<strong>Images présentes sur le serveur</strong>
				<a class="image" v-for="(image, index) in this.images_existantes" :key="`image${index}`" :href="`https://mediabase.lezardscreation.fr/wp-content/uploads_image/${image}`" target="_blank">
					<i class="fa-regular fa-arrow-up-right-from-square"></i> {{ image }}
				</a>
			</div>
		</div>
	</section>
</template>
<script>
import readXlsxFile from "read-excel-file";
import slugify from "slugify";
import Header from "../components/Header";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { DataTable } from "simple-datatables";
import { glob } from "glob";

export default {
	name: "globales",
	components: { Header },
	data() {
		return {
			excel: null,
			zones: [],
			codes: [],
			new_globale: {
				code_court: "",
				description: "",
				type: "texte",
				valeur_retournee: "",
				image_json: [],
			},
			retour: "",
			new_image_loading: false,
			replace_image: false,
			query: "",
			is_replacing_data: false,
			replacing_text: "",
			selectedFile: null,
			filename: '',
			uploadState: false,
			uploadMessage: "",
			images_existantes: [],
		};
	},
	computed: {
		// ...mapGetters({
		// 	globales: "globales",
		// 	zones: "zones",
		// }),
		globales: function () {
			return this.$store.state.globales;
		},
		zones_filtrees: function () {
			if (this.query.value === "") {
				return this.$store.state.zones;
			} else {
				return this.$store.state.zones.filter((zone) => {
					return slugify(zone.fields.nom_zone.toLowerCase()).includes(slugify(this.query.toLowerCase()));
				});
			}
		}
	},
	mounted() {
		this.getNewGlobales();
		this.scanDir();
	},
	methods: {
		async handleExcel() {
			readXlsxFile(this.$refs.globalecsv.files[0]).then((rows) => {
				this.is_replacing_data = true;
				this.replacing_text = "Lecture du fichier Excel";
				this.excel = [];
				this.zones = [];
				this.codes = [];
				this.excel = rows;
				console.log(rows);
				let ligne_des_zones = 1; // Numéro de ligne dans le fichier EXCEL - 1 en partant de 0
				let colonne_des_types = 2; // Numéro de colonne dans le fichier Excel - 2 en partant de 0
				let colonne_des_codes = 3; // Numéro de colonne dans le fichier Excel - 3 en partant de 0
				let debut_des_valeurs = 2; // Numero de la ligne ou débute les valeurs - 2 en partant de 0
				let colonne_defaut = 5;
				let codes_totaux = this.excel.length;
				let zones_totales = this.excel[ligne_des_zones].length;

				let regions_finales = [
					{
						nom_zone: this.excel[ligne_des_zones][colonne_defaut],
					},
				];
				let donnees_finales = [];

				for (let i = debut_des_valeurs; i < codes_totaux; i++) {
					let valeur = this.excel[i][colonne_defaut].toString();
					let type = this.excel[i][colonne_des_codes - 1].toLowerCase();
					console.log(type, valeur);
					let code = {
						type: type,
						code_court: slugify(this.excel[i][colonne_des_codes]).toLowerCase(),
						description: this.excel[i][colonne_des_codes + 1].toLowerCase(),
						valeur_retournee: type === "image" ? "https://mediabase.lezardscreation.fr/wp-content/uploads_image/" + valeur : valeur,
						regions: [
							{
								zone: this.excel[ligne_des_zones][colonne_defaut],
								valeur: type === "image" ? "https://mediabase.lezardscreation.fr/wp-content/uploads_image/" + valeur : valeur,
							},
						],
					};
					for (let j = colonne_defaut + 1; j < zones_totales; j++) {
						let valeur = this.excel[i][j];
						if (valeur === "➜") {
							valeur = this.excel[i][colonne_defaut].toString();
						}
						if (valeur === "X" || valeur === "x") {
							valeur = this.excel[i][colonne_defaut].toString();
						}
						code.regions.push({
							zone: this.excel[ligne_des_zones][j],
							valeur: type === "image" ? "https://mediabase.lezardscreation.fr/wp-content/uploads_image/" + valeur : valeur,
						});
						if (i === debut_des_valeurs) {
							regions_finales.push({
								nom_zone: this.excel[ligne_des_zones][j],
							});
						}
					}

					donnees_finales.push(code);
				}

				this.replacing_text = "Mise à jour des codes";
				this.create_codes_courts(donnees_finales).then(() => {
					this.replacing_text = "Mise à jour des zones";
					this.create_zones(regions_finales).then(() => {
						setTimeout(() => {
							this.is_replacing_data = false;
						}, 5000);
						this.replacing_text = "Toutes les données ont été traitées, merci";
					});
				});
			});
		},

		async create_codes_courts(data) {
			for (const code of data) {
				await this.createNewCodeFromExcel(code);
			}
		},
		async create_zones(zones) {
			for (const zone of zones) {
				await this.createNewZoneFromExcel(zone);
			}
		},
		toggleVisibily(e) {
			e.currentTarget.classList.toggle("deployed");
		},
		handleNewImageGlobale(event) {
			this.new_image_loading = true;
			this.$store
				.dispatch("uploadImage", {
					file: event.target.files[0],
				})
				.then((res) => {
					var slideFile = new Blob([JSON.stringify({ preview: res.lien, width: res.meta.width, height: res.meta.height })], { type: "application/json" });
					let jsonFile = new File([slideFile], `${Date.now()}.json`);
					this.$store
						.dispatch("uploadJson", jsonFile)
						.then((file) => {
							this.new_globale.image_json = [{ url: process.env.VUE_APP_MAUFFREY_API_URL + file.data }];
							this.new_image_loading = false;
							this.retour = "Votre image a bien été déposée, merci.";
						})
						.catch((err2) => {
							this.new_image_loading = false;
							this.retour = err2;
							return;
						});
				})
				.catch((err) => {
					this.new_image_loading = false;
					this.retour = err;
					return;
				});
		},
		handleImageZone(event, globale, zone) {
			this.replace_image = true;
			this.$store
				.dispatch("uploadImage", {
					file: event.target.files[0],
				})
				.then((res) => {
					this.updateZoneImage({ globale: globale, zone: zone, url: res.lien, width: res.meta.width, height: res.meta.height });
					this.replace_image = false;
				});
		},
		handleImageGlobale(event, id) {
			this.replace_image = true;
			this.$store
				.dispatch("uploadImage", {
					file: event.target.files[0],
				})
				.then((res) => {
					this.updateGlobaleImage({ id: id, url: res.lien, width: res.meta.width, height: res.meta.height });
					this.replace_image = false;
					// var slideFile = new Blob([JSON.stringify({ preview: res.lien, width: res.meta.width, height: res.meta.height })], { type: "application/json" });
					// let jsonFile = new File([slideFile], `${Date.now()}.json`);
					// this.$store
					// 	.dispatch("uploadJson", jsonFile)
					// 	.then((file) => {
					// 		this.replace_image = false;
					// 		this.$store.commit("updateGlobaleImage", { url: file.data, id: id });
					// 	})
					// 	.catch((err2) => {
					// 		this.replace_image = false;
					// 		return;
					// 	});
				})
				.catch((err) => {
					this.replace_image = false;
					return;
				});
		},
		onFileChange(e) {
			const file = e.target.files[0];
			const ext = file.name.slice(file.name.lastIndexOf('.'));

			if (ext !== '.jpg' && ext !== '.png') {
				alert('Only JPG and PNG files are allowed');
				return;
			}

			this.selectedFile = file;
			this.filename = file.name;
		},
		uploadImage() {
			if (!this.selectedFile) {
				alert('No file selected');
				return;
			}
			this.uploadState = true;
			const formData = new FormData();
			formData.append('token', '2757528D78F8B3B234F73FCD637C3'); // ajoutez votre token ici
			formData.append('fileToUpload', new Blob([this.selectedFile], { type: this.selectedFile.type }), this.filename);

			fetch(process.env.VUE_APP_MEDIABASE_FTP, {
				method: 'POST',
				body: formData
			}).then(response => {
				if (!response.ok) {
					throw new Error('Network response was not ok');
				}
				return response.text();
			}).then(data => {
				let res = JSON.parse(data);
				this.uploadState = false;
				this.uploadMessage = res.message;
			}).catch(error => {
				this.uploadState = false;
				alert("Une erreur est survenue pendant le dépôt du fichier");
			});
		},
		scanDir() {
			const formData = new FormData();
			formData.append('token', '2757528D78F8B3B234F73FCD637C3'); // ajoutez votre token ici

			fetch(process.env.VUE_APP_MEDIABASE_SCANDIR, {
				method: 'POST',
				body: formData
			})
				.then(response => response.json())
				.then(images => {
					this.images_existantes = images;
				})
				.catch(error => console.error('Erreur:', error));
		},
		...mapActions({
			createNewCodeFromExcel: "createNewCodeFromExcel",
			createNewZoneFromExcel: "createNewZoneFromExcel",
			populateGlobales: "populateGlobales",
			getNewGlobales: "getNewGlobales",
			createNewZone: "createNewZone",
			createNewTextGlobale: "createNewTextGlobale",
			createNewTextImage: "createNewTextImage",
		}),
		...mapMutations({
			updateGlobaleImage: "updateGlobaleImage",
			updateZoneImage: "updateZoneImage",
			updateGlobale: "updateGlobale",
			createGlobale: "createGlobale",
			deleteGlobale: "deleteGlobale",
			deleteZone: "deleteZone",
			updateCodeCourt: "updateCodeCourt",
			updateDefaultValue: "updateDefaultValue",
			updateCodeDescription: "updateCodeDescription",
			updateConfiguration: "updateConfiguration",
			deleteCurrentImageGlobale: "deleteCurrentImageGlobale",
		}),
	},
};
</script>

<style lang="scss">
#globales {
	background: white;
	background-image: url("../assets/img/fondruche3-bis.jpg");
	background-position: bottom center;
	background-size: cover;
	min-height: 100vh;
	background-attachment: fixed;
	.donneesglobales {
		padding: 30px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 30px;
		.formulaire {
			border-right: solid 1px rgba(black, 0.05);
			padding: 20px 20px 50px 20px;
			margin-bottom: 50px;
			grid-column: span 1;
			h4 {
				padding: 16px;
				margin: 0;
				font-weight: 500;
			}
			form {
				border-radius: 20px;
				display: flex;
				flex-wrap: wrap;
				border-radius: 20px;
				position: relative;
				flex-direction: column;
				.submit {
					button {
						font-size: 13px;
						&:hover {
							transform: translateY(5px);
							background: black;
							color: white;
						}
						i {
							margin: 0;
							padding: 0;
						}
					}
				}
				.form-group {
					background: $gris;
					border-radius: 20px;
					margin-bottom: 10px;
					display: flex;
					padding: 6px;
					gap: 10px;
					flex: 50%;
					position: relative;
					label {
						font-weight: 500;
						font-size: 9px;
						text-transform: uppercase;
						display: flex;
						color: $rouge;
						text-align: right;
						flex: 0 0 auto;
						align-items: flex-start;
						border-radius: 20px;
						padding-right: 10px;
						background: white;
						padding: 20px 0 0 10px;
						position: absolute;
						top: 0;
						left: 20px;
						padding: 4px 10px;
						background: white;
						small {
							display: block;
							margin-top: 4px;
						}
					}
					.droite {
						flex: 4;
						background: white;
						box-shadow: inset 1px 1px 3px rgba(black, 0.1);
						border-radius: 20px;
						padding: 10px;
						.retour span {
							font-size: 12px;
							color: $vert;
							display: block;
							background: $vertclair;
							padding: 10px;
							margin: 10px;
							border-radius: 20px;
						}
					}
					input[type="text"],
					textarea,
					select {
						flex: 1;
						height: 100%;
						border: none;
						box-shadow: inset 1px 1px 3px rgba(black, 0.1);
						padding: 0 10px;
						background: white;
						height: 50px;
						border: none;
						outline: none;
						width: 100%;
						font-size: 13px;
						color: black;
						border-radius: 20px;
						font-weight: 400;
					}
					input[type="file"] {
						padding: 10px;
						height: 46px;
					}
					textarea {
						padding: 10px;
						font-weight: 300;
						font-size: 12px;
						line-height: 16px;
						min-height: 76px;
					}
					&.uploadzone {
						position: relative;
						input[type="file"] {
							border: none;
							border-radius: 0px;
							box-shadow: none;
							background: red;
							visibility: hidden;
							& + label {
								font-size: 20px;
								width: 40px;
								height: 40px;
								border-radius: 50%;
								display: flex;
								align-items: center;
								justify-content: center;
								color: $rouge;
								background: $rougeclair;
								transition: all 0.2s ease;
								cursor: pointer;
								margin-top: 20px;
								&:hover {
									background: $rouge;
									color: $rougeclair;
								}
							}
						}
					}
				}
			}
		}
		.tablezone {
			padding: 0px;
			border-radius: 20px;
			grid-column: span 2;
			.dataTable-bottom {
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				font-size: 11px;
				font-weight: 500;
				background: $gris;
				border-radius: 0 0 20px 20px;
				padding-bottom: 40px;
				.dataTable-info {
					padding: 0px 20px 15px 20px;
					opacity: 0.7;
					font-style: italic;
				}
				.dataTable-pagination {
					background: white;
					border-radius: 8px;
					box-shadow: 3px 3px 12px rgba(black, 0.05);
					ul {
						display: flex;
						margin: 0;
						padding: 0;
						list-style: none;
						li {
							border-left: solid 1px rgba(black, 0.05);
							transition: all 0.3s ease;
							a {
								color: black;
								padding: 12px 16px;
								display: block;
							}
							&.pager {
								font-size: 10px;
							}
							&.active {
								a {
									color: $rouge;
								}
							}
							&:hover {
								background: $gris;
							}
						}
					}
				}
			}
			.dataTable-top {
				display: flex;
				padding: 20px 0 20px 20px;
				align-items: center;
				justify-content: space-between;
				font-size: 12px;
				font-weight: 500;
				select {
					padding: 10px;
					border: none;
					border-radius: 15px;
					background: $gris;
					margin: 0 6px;
					font-weight: 500;
					outline: none;
				}
				.dataTable-search {
					border-radius: 15px;
					padding: 0;
					background: $gris;
					border-radius: solid 1px $gris2;
					border: solid 1px transparent;
					transition: all 0.3s ease;
					input {
						width: 100%;
						height: 43px;
						border: none;
						padding: 0 16px;
						border-radius: 15px;
						width: 280px;
						background: transparent;
						font-weight: 500;
						font-size: 13px;
					}
				}
			}
			table {
				width: 100%;
				background: white;
				border-radius: 8px;
				border: solid 1px $gris2;
				box-shadow: 3px 3px 12px rgba(black, 0.05);
				thead {
					tr {
						th {
							border-bottom: thin solid $gris2;
							height: 48px;
							padding: 0 10px;
							user-select: none;
							text-align: left;
							font-size: 11px;
							a {
								font-weight: 600;
								color: black !important;
							}
						}
					}
				}
				tbody {
					tr {
						padding: 0;
						margin: 0;
						border: none;
						cursor: pointer;
						td {
							border: none;
							border-bottom: thin solid $gris;
							height: 48px;
							font-size: 12px;
							padding: 0 10px;
							font-weight: 300;
							transition: all 0.3s ease;
							img {
								width: 100%;
								height: auto;
								max-width: 100px;
								border-radius: 10px;
								margin: 10px 0;
							}
							&:nth-child(2),
							&:nth-child(3) {
								font-weight: bold;
							}
							&:last-child {
								span {
									background: $gris2;
									display: inline-block;
									padding: 6px 12px;
									font-weight: 500;
									font-size: 12px;
									border-radius: 20px;
									&.brouillon {
										background: #fdf4e0;
										color: $jaune;
									}
									&.pret {
										background: #f4f8ff;
										color: $bleu;
									}
									&.envoye {
										background: #e5f7ef;
										color: $vert;
									}
								}
							}
							&.dataTables-empty {
								display: none;
							}
							input[type="text"],
							textarea {
								text-align: left;
								border: none;
								background: $gris;
								padding: 10px;
								width: 100%;
								height: 36px;
								border-radius: 20px;
								font-weight: 400;
								&:focus {
									background: $rougeclair;
									box-shadow: inset 1px 1px 3px rgba($rouge, 0.2);
								}
							}
							select {
								background: transparent;
								color: black;
								font-weight: 500;
								border: none;
								appearance: none;
								outline: none;
								cursor: pointer;
								padding: 6px 14px;
								text-align: center;
								border-radius: 20px;

								&.texte {
									background: $jauneclair;
									color: $jaune;
								}
								&.image {
									background: $vertclair;
									color: $vert;
								}
							}
							.group {
								display: flex;
								align-items: center;
							}
							.fa-trash {
								width: 30px;
								height: 30px;
								transition: all 0.3s ease;
								display: flex;
								align-items: center;
								justify-content: center;
								border-radius: 50%;
								margin: 0 auto;
								&:hover {
									background: $rougeclair;
									color: $rouge;
									box-shadow: inset 1px 1px 4px rgba(black, 0.2);
								}
							}
							.uploadzone {
								position: relative;
								input[type="file"] {
									border: none;
									border-radius: 0px;
									box-shadow: none;
									background: red;
									visibility: hidden;
									display: none;
									& + label {
										font-size: 20px;
										width: 40px;
										height: 40px;
										border-radius: 50%;
										display: flex;
										align-items: center;
										justify-content: center;
										color: $rouge;
										background: $rougeclair;
										transition: all 0.2s ease;
										cursor: pointer;
										margin-right: 20px;
										&:hover {
											background: $rouge;
											color: $rougeclair;
										}
									}
								}
							}
						}
						&:hover {
						}
					}
				}
			}
			.dataTable-container {
				padding: 40px;
				border-radius: 20px 20px 0 0;
				background: $gris;
			}
		}
	}
	.new_interface {
		.chargement {
			padding: 3em;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			gap: 10px;
			width: 300px;
			border-radius: 6px;
			margin: 30px auto;
			background: rgba(black, 0.05);
			i,
			svg {
				color: $rouge;
			}
		}
		.tableau2D {
			padding: 60px;
			display: flex;
			flex-direction: column;
			overflow-x: auto;
			background: white;

			.firsline {
				display: flex;
				flex-wrap: nowrap;
				.cell {
					flex: 0 0 200px;
					border-right: solid 1px lightgray;
					padding: 4px 10px;
					font-weight: 400;
					font-size: 13px;
					background: rgba(black, 0.04);
					border-bottom: solid 1px lightgray;
					border-top: solid 1px lightgray;
					line-height: 1.1em;
					color: slategray;
					display: flex;
					flex-direction: column;
					justify-content: center;
					text-align: center;
					&.last_colonne {
						display: flex;
						align-items: center;
						i {
							width: 30px;
							height: 30px;
							background: $bleu;
							border-radius: 50%;
							color: $bleuclair;
							display: flex;
							align-items: center;
							justify-content: center;
							font-size: 18px;
							cursor: pointer;
							&:hover {
								background: $vert;
								color: $vertclair;
							}
						}
					}
					.delete {
						display: none;
						&:hover {
							color: $rouge;
						}
					}
					&:hover {
						.delete {
							display: flex;
							animation: appearFromRight 0.3s ease;
						}
					}
				}
				.first-cell {
					flex: 0 0 250px;
					position: relative;
					background: transparent;
					input {
						border: none;
						background: transparent;
						height: 30px;
						padding: 0 15px;
						border-radius: 20px;
					}
					i,
					svg {
						position: absolute;
						right: 20px;
					}
				}
			}
			.line {
				display: flex;
				flex-wrap: nowrap;
				background: white;
				.cell {
					flex: 0 0 200px;
					border-right: solid 1px $vertclair;
					padding: 10px;
					font-size: 12px;
					line-height: 1.3em;
					color: black;
					font-weight: 400;
					display: flex;
					height: 44px;
					overflow: auto;
					flex-direction: column;
					justify-content: flex-start;
					text-align: left;
					&.image_tool,
					.image_tool {
						position: relative;
						display: flex;
						height: 100%;
						min-height: 25px;
						padding: 10px;
						input {
							display: none;
						}
						label {
							position: absolute;
							right: 10px;
							top: calc(50% - 6px);
							cursor: pointer;
							display: none;
							&:hover {
								color: $bleu;
							}
						}
						img {
							width: auto;
							height: 20px;
							object-fit: cover;
							border-radius: 2px;
							box-shadow: 1px 1px 3px rgba(black, 0.05);
							border: none;
							background: $gris;
						}
						&:hover {
							label {
								display: block;
								animation: appearFromRight 0.3s ease;
							}
						}
						.empty {
							width: 30px;
							height: 24px;
							border: dashed 2px rgba(black, 0.1);
							border-radius: 2px;
							background: rgba(black, 0.01);
						}
						.delete {
							background: $rougeclair;
							color: $rouge;
							font-weight: 500;
							font-size: 11px;
							padding: 2px 4px;
							border-radius: 3px;
							margin-left: 5px;
							margin-bottom: 4px;
							cursor: pointer;
							transition: all 0.3s ease;
							&:hover {
								background: $rouge;
								color: $rougeclair;
							}
						}
					}
					&.speciale {
						padding: 0;
						.text-tool {
							padding: 10px;
							height: 100%;
							width: 100%;
							display: flex;
							justify-content: flex-start;
							align-items: center;
							&:focus {
								outline-color: $vert;
								z-index: 10;
								outline-width: 2px;
								outline-offset: -2px;
							}
						}
						.image_tool {
							padding: 10px;
							height: 100%;
							width: 100%;
							display: flex;
							justify-content: flex-start;
							align-items: center;
						}
					}
					&:focus {
						outline: solid 1px slategray;
						box-shadow: 0px 3px 20px rgba(black, 0.3);
						z-index: 10;
					}
					input[type="text"] {
						border: none;
						background: none;
						height: 20px;
						padding: 0 10px;
						width: 180px;
						&:focus {
							box-shadow: 0px 0px 2px $bleu;
						}
					}
					&.default {
					}
				}
				.first-cell {
					flex: 0 0 250px;
					display: flex;
					align-items: center;
					justify-content: flex-start;
					flex-direction: row;
					gap: 5px;
					font-weight: 400;
					color: slategray;
					background: rgba(black, 0.04) !important;
					border-bottom: solid 1px rgba(lightgray, 0.3);
					.delete {
						display: none;
						&:hover {
							color: $rouge;
						}
					}
					.type {
						background: $vert;
						color: $vertclair;
						width: 46px;
						text-align: center;
						border: none;
						border-radius: 12px;
						padding: 3px 6px;
						font-size: 11px;
						&.image {
							background: $jaune;
							color: $jauneclair;
						}
					}
					.code {
						padding: 5px;
						font-size: 12px;
						//font-weight: 500;
						&:focus {
							outline-color: $vert;
						}
					}
					&:hover {
						.delete {
							display: block;
							animation: appearFromLeft 0.3s ease;
						}
					}
				}
				&:nth-child(odd) {
					.cell {
						background: rgba($vertclair, 0.4);
					}
				}
			}
			.last-line {
				display: flex;
				.first-cell {
					display: flex;
					flex: 0 0 250px;
					align-items: center;
					justify-content: center;
					gap: 10px;
					padding: 20px;
					i {
						width: 30px;
						height: 30px;
						background: $vert;
						border-radius: 50%;
						color: $vertclair;
						display: flex;
						align-items: center;
						justify-content: center;
						font-size: 18px;
						cursor: pointer;
						&:last-child {
							background: $jaune;
							color: $jauneclair;
						}
						&:hover {
							background: black;
							color: white;
						}
					}
				}
			}
		}
	}
	.importcsv {
		#globalecsv {
			padding: 30px 60px 0;
		}
	}
	.importcsv {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		input {
			grid-column: span 2;
		}
	}
	.zones,
	.code_cours {
		padding: 60px;
		ul {
			li {
				display: flex;
				gap: 10px;
				align-items: center;
				margin-bottom: 10px;
				.bulle {
					width: 10px;
					height: 10px;
					border-radius: 50%;
					background: $rouge;
					&.active {
						background: $vert;
					}
				}
			}
		}
	}
	.upload_informations {
		margin: 30px 60px;
		padding: 30px 0;
		font-weight: 700;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		align-items: flex-start;
		gap: 10px;
		font-size: 16px;
		font-weight: bold;
		border-top: dashed 2px $rouge;
		border-bottom: dashed 2px $rouge;
		i {
			color: $rouge;
			display: block;
		}
	}
	.simple_inferface {
		margin: 60px;
		font-size: clamp(2.5rem, 1.25rem + 2.5vw, 3.75rem);
		font-weight: 600;

		strong {
			font-weight: bold;
			color: $rouge;
		}
	}
	.gestion_ftp {
		margin: 100px 60px;
		display: flex;
		gap: 40px;
		.gauche {
			flex: 0 0 40%;
			h4 {
				font-size: 1.6rem;
				margin-bottom: 20px;
			}
			.form {
				display: flex;
				flex-direction: column;
				gap: 10px;
				align-items: flex-start;
				input[type="text"] {
					height: 40px;
					appearance: none;
					padding: 0 30px;
					border: solid 1Px rgba(black, 0.1);
					border-radius: 8px;
					box-shadow: 2px 2px 8px rgba(black, 0.05);
					width: 80%;
					margin-bottom: 20px;
					transition: all .3s ease;
					&:focus {
						box-shadow: 4px 4px 12px rgba($rouge, 0.1);
					}
				}
			}
		}
		.droite {
			flex: 0 0 60%;
			background: rgba(black, 0.01);
			padding: 30px;
			display: flex;
			flex-direction: column;
			.image {
				padding: 4px 0;
				color: inherit;
			}
			strong {
				font-weight: bold;
				color: $rouge;
				display: block;
				margin-bottom: 20px;
			}
		}
	}
}
</style>
